import React, { useEffect, useReducer } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import 'rsuite/styles/index.less'
import 'rsuite/dist/rsuite.min.css'

import 'antd/dist/reset.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { AuthReducer } from './auth/AuthReducer'
import { AuthContext } from './auth/AuthContext'
import { SocketProvider } from './context/SocketContext'
import { ForgetPassword } from './views/Auth/ForgetPassword/ForgetPassword'
import { ResetPassword } from './views/Auth/ResetPassword/ResetPassword'
import { MetricsProvider } from './context/MetricsContext'
import { ConfirmateLeg } from './views/confirmate-leg/ConfirmateLeg'
import { PreferencesProvider } from './context/Preferences/PreferencesContext'
import { DashboardProvider } from './context/DashboardContext'
import { SearchProvider } from './context/SearchContext'
import { ConfigProvider } from 'antd'
import { ProviderAntd } from './context/Preferences/ProviderAntd'
import { PrivacyView } from './views/Privacy/PrivacyView'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const init = () => {
  return JSON.parse(localStorage.getItem('user')) || { logged: false }
}

function App() {
  const [user, dispatch] = useReducer(AuthReducer, {}, init)

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user))
  }, [user])

  useEffect(() => {
    const titleEnv = process.env.REACT_APP_TITLE

    document.title = titleEnv || 'Truckport'
  }, [])

  return (
    <PreferencesProvider>
      <ProviderAntd>
        <AuthContext.Provider value={{ user, dispatch }} className="notranslate">
          <SocketProvider>
            <MetricsProvider>
              <SearchProvider>
                <DashboardProvider>
                  <HashRouter>
                    <React.Suspense fallback={loading}>
                      <Routes>
                        <Route exact path="/login" name="Login Page" element={<Login />} />
                        <Route
                          exact
                          path="/forget-password"
                          name="Forget Password"
                          element={<ForgetPassword />}
                        />
                        <Route
                          exact
                          path="/reset-password"
                          name="Reset Password"
                          element={<ResetPassword />}
                        />
                        <Route exact path="/register" name="Register Page" element={<Register />} />
                        <Route exact path="/404" name="Page 404" element={<Page404 />} />
                        <Route exact path="/500" name="Page 500" element={<Page500 />} />
                        <Route
                          exact
                          path="/confirm-leg"
                          name="Confirm Leg"
                          element={<ConfirmateLeg />}
                        />
                        <Route
                          exact
                          path="/privacy"
                          name="privacy policies"
                          element={<PrivacyView />}
                        />
                        <Route path="*" name="Home" element={<DefaultLayout />} />
                      </Routes>
                    </React.Suspense>
                  </HashRouter>
                </DashboardProvider>
              </SearchProvider>
            </MetricsProvider>
          </SocketProvider>
        </AuthContext.Provider>
      </ProviderAntd>
    </PreferencesProvider>
  )
}

export default App
