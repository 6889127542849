import { Button, Loader, Message, useToaster } from 'rsuite'
import styles from './Forget.module.css'
import { useState } from 'react'
import { validateEmail } from '../../../utils/Validators'
import { forgotPassword } from 'src/Services/AuthServices'
import { useSystemLogos } from 'src/hooks/useSystemLogos'
import { LetterIcon } from 'src/assets/Icons/LetterIcon'
import IconInbox from 'src/assets/Icons/InboxIcon'
import { useDarkMode } from 'src/hooks/useDarkMode'
import { useNavigate } from 'react-router-dom'

const errorMessage = (
  <Message showIcon type="error" closable>
    Sorry, the email could not be sent
  </Message>
)
export const ForgetPassword = () => {
  const toaster = useToaster()

  const { darkMode } = useDarkMode() 
  const navigate = useNavigate()

  const [logos, isLoading] = useSystemLogos()
  const [loading, setLoading] = useState(false)
  const [emailRecover, setEmailRecover] = useState('')
  const [emailWasSended, setEmailWasSended] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (validateEmail(emailRecover)) {
      try {
        setLoading(true)
        const resForget = await forgotPassword(emailRecover)
        if (resForget) {
          setEmailWasSended(true)
        } else {
          toaster.push(errorMessage, { placement: 'topCenter', duration: 5000 })
        }
      } catch (error) {
        toaster.push(errorMessage, { placement: 'topCenter', duration: 5000 })
      } finally {
        setLoading(false)
      }
    }
  }
  const handleChange = (event) => {
    const value = event.target.value
    setEmailRecover(value)
  }

  return (
    <div className={`${styles.mainContainer} ${ darkMode && styles.darkmodeForget}`}>
      <div className={styles.contentContainer}>
        <div>
          <div className={styles.imageContainer}>
            {isLoading && !logos?.loginImage?.url ? (
              <div className={styles.skeleton}></div>
            ) : (
                <img src={darkMode ? logos?.sidebarImageDark?.url : logos?.loginImage?.url} alt="Logo Truckport" className={styles.logo} />
            )}
          </div>
        </div>
        <h1 className={styles.titleForm}>Recover your password</h1>

        {!emailWasSended ? (
          <form onSubmit={handleSubmit} className={styles.formContainer}>
            <p className={styles.subtitle}>Enter your email address to change your password</p>
            <div className={styles.inputText}>
              <label htmlFor="Name">Email</label>
              <div className={styles.contentInput}>
                <LetterIcon color={darkMode ? "#FFF" : "#000842"} />

                <input
                  disabled={loading}
                  className={styles.inputForm}
                  placeholder="Enter your email address"
                  id="email"
                  name="email"
                  onChange={handleChange}
                />
                {loading && <Loader />}
              </div>
            </div>
            <div className={styles.containerButton}>
              <button disabled={loading} type="submit">
                Reset password {loading && <Loader />}
              </button>
            </div>
          </form>
        ) : (
          <div className={styles.postSentEmail}>
              <IconInbox className={styles.inboxIcon} color={darkMode ? "#FFF" : "#084DA4"} />
            <p className={styles.confirmationText}>
              A password reset link has been sent to {emailRecover}
            </p>
            <div className={styles.containerButton}>
              <button disabled={loading} onClick={handleSubmit}>
                Resend email {loading && <Loader />}
              </button>
            </div>
          </div>
        )}
        <div className={styles.backToLoginContainer}>
          <Button
            appearance="link"
            onClick={() => {
              navigate('/')
            }}
          >
            Back to Login
          </Button>
        </div>
      </div>
    </div>
  )
}
