import { Loader, Message, useToaster } from 'rsuite'
import styles from './Reset.module.css'
import { useEffect, useState } from 'react'
import { useSystemLogos } from 'src/hooks/useSystemLogos'
import { useForm } from 'src/hooks/useForm'
import { EyeInvisibleIcon } from 'src/assets/Icons/EyeInvisibleIcon'
import { PaylockIcon } from 'src/assets/Icons/PaylockLoginIcon'
import { useLocation, useNavigate } from 'react-router-dom'
import { resetPassword } from 'src/Services/AuthServices'

const errorMessage = (
  <Message showIcon type="error" closable>
    Sorry, the email could not be sent
  </Message>
)
const successMessage = (
  <Message showIcon type="success" closable>
    The password has been reset
  </Message>
)
function validatePayload(payload) {
  if (!payload.code) {
    return "You don't have the permissions to make this request."
  }

  if (!payload.password) {
    return 'The password field is required.'
  }

  if (!payload.passwordConfirmation) {
    return 'The password confirmation field is required.'
  }

  if (typeof payload.code !== 'string') {
    return "You don't have the permissions to make this request."
  }

  if (typeof payload.password !== 'string') {
    return 'The password field must be a string.'
  }

  if (typeof payload.passwordConfirmation !== 'string') {
    return 'The password confirmation field must be a string.'
  }

  if (payload.password !== payload.passwordConfirmation) {
    return 'The passwords do not match.'
  }

  return null
}
export const ResetPassword = () => {
  const toaster = useToaster()
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const code = searchParams.get('code')
  const [error, setError] = useState(null)
  const [logos, isLoading] = useSystemLogos()
  const [loading, setLoading] = useState(false)
  const [typePass, setTypePass] = useState('password')

  const [passwords, setPasswords] = useForm({
    password: '',
    confirmPassword: '',
  })

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()
    try {
      if (error) {
        throw error
      }
      const payload = {
        code,
        password: passwords.password,
        passwordConfirmation: passwords.confirmPassword,
      }
      const response = await resetPassword(payload)
      if (response) {
        toaster.push(successMessage, { placement: 'topCenter', duration: 5000 })
        navigate('/', {
          replace: true,
        })
      } else {
        toaster.push(errorMessage, { placement: 'topCenter', duration: 5000 })
      }
    } catch (error) {
      toaster.push(errorMessage, { placement: 'topCenter', duration: 5000 })
      setLoading(false)
    }
  }

  const changeTypePassword = () => {
    if (typePass == 'text') {
      setTypePass('password')
      return
    }
    setTypePass('text')
  }
  useEffect(() => {
    const payload = {
      code,
      password: passwords.password,
      passwordConfirmation: passwords.confirmPassword,
    }
    const error = validatePayload(payload)
    setError(error)
  }, [passwords])
  return (
    <div className={styles.mainContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.imageContainer}>
          {isLoading && !logos[0]?.loginImage.url ? (
            <div className={styles.skeleton}></div>
          ) : (
            <img src={logos[0]?.loginImage.url} alt="Logo Truckport" className={styles.logo} />
          )}
        </div>
        <h1 className={styles.titleForm}>Set new password</h1>

        <form onSubmit={handleSubmit} className={styles.formContainer}>
          <div className={styles.inputText}>
            <label htmlFor="password">Password</label>
            <div className={styles.inputPassword}>
              <PaylockIcon />
              <input
                className={styles.inputForm}
                placeholder="Password"
                type={typePass}
                id="password"
                name="password"
                onChange={setPasswords}
              />
              {loading && <Loader />}
              <EyeInvisibleIcon className={styles.iconEye} onClick={changeTypePassword} />
            </div>
          </div>
          <div className={styles.inputText}>
            <label htmlFor="password">Confirm password</label>
            <div className={styles.inputPassword}>
              <PaylockIcon />
              <input
                className={styles.inputForm}
                placeholder="Confirm password"
                type={typePass}
                id="confirmPassword"
                name="confirmPassword"
                onChange={setPasswords}
              />
              {loading && <Loader />}
              <EyeInvisibleIcon className={styles.iconEye} onClick={changeTypePassword} />
            </div>
          </div>
          {error && <p className={styles.hintText}>{error}</p>}
          <div className={styles.containerButton}>
            <button type="submit">Reset password {loading && <Loader />}</button>
          </div>
        </form>
      </div>
    </div>
  )
}
