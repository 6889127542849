export const API_TRUCKPORT = process.env.REACT_APP_BASE_URL
export const API_ZIPCODE = process.env.REACT_APP_ZIPCODEKEY

export const TIME_INACTIVE = process.env.REACT_APP_TIME_INACTIVE || 1680000
export const TIME_EXTENDS = process.env.REACT_APP_TIME_EXTENDS || 120
// export const GOOGLEMAPS_APIKEY = process.env.REACT_APP_GOOGLE_MAPS_KEY || ''
// Solamente para QA es necesario usar el valor de la api key de google directamente por algún problema al reconocer esta variable de entorno
export const GOOGLEMAPS_APIKEY =
  process.env.REACT_APP_GOOGLE_MAPS_KEY || 'AIzaSyDIGLpQhomPEeh2QiCuKxoPV5JMr0xIQPI'

export const COLUMNS_INVOICE =
  'TRNSTYPE |CLASS | DUEDATE | DATE | ACCT | NAME | DOCUM| AMOUNT | INVITEM | CUSTOMER REF |ORDER | CONTAINER | MASTER BL|'

export const ROL_ADMIN = 'admin'

export const MONTHS = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  10: 'October',
  11: 'November',
  12: 'December',
}

export const COLORS_CATEGORYS = {
  DP: '#3083E1',
  OWE: '#165BAA',
  OWL: '#26C066',
  ID: '#169598',
  ED: '#5D5AF2',
}
export const CATEGORYS_ORDERS = {
  DP: 'DP',
  OWE: 'OWE',
  OWL: 'OWL',
  ID: 'ID',
  ED: 'ED',
}

export const KEYS_SORT = {
  ascend: 'asc',
  descend: 'desc',
}

export const TYPECONSIGNEE = 7

export const TERMS_CUSTOMERDEFAULT = [
  {
    value: 15,
    label: '15',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 45,
    label: '45',
  },
]

export const CUSTOMER_ROLE = [
  {
    value: 3,
    label: 'Admin',
  },
  {
    value: 1,
    label: 'Authenticated',
  },
  {
    value: 8,
    label: 'Billing',
  },
  {
    value: 9,
    label: 'Customer',
  },
  {
    value: 4,
    label: 'Data Entry',
  },
  {
    value: 5,
    label: 'Dispatcher',
  },
  {
    value: 6,
    label: 'Driver',
  },
  {
    value: 2,
    label: 'Public',
  },
]

export const KEY_COLUMNS_ORDER = 'order_columns'
export const KEY_COLUMNS_ORDER_EXTRAS = 'order_columns_extras'
export const KEY_FILTER_ORDER = 'order_filters'
export const KEY_COLUMN_SORT = 'order_column_sort'
export const ENVS_NAMES = {
  truckport: 'truckport',
  portfleet: 'portfleet',
  drayx: 'drayx',
}
export const PORTAL_NAME = process.env.REACT_APP_CUSTOMER || ENVS_NAMES.portfleet
