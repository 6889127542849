import { useMemo } from "react"
import { usePreferences } from "./usePreferences"

const COLORS = {
    dark: {
        background: "#151B23",
        text: "#FFF",
        card: "#212B36",
        classname: "darkmode",
        textBlack: "#FFF",
        textBlue: "#FFF",
        textLabelChart: "#FFF",
        buttons: {
            primary: "#084DA4"
        },
        darkMode: true
    },
    ligth: {
        background: "#F9F9F9",
        text: "#284EB2",
        card: "#FFF",
        classname: "",
        textBlack: "#474248",
        textBlue: "#084DA4",
        textLabelChart: "#4F4F4F",
        buttons: {
            primary: "#084DA4"
        },
        darkMode: false
    }
}

export const useDarkMode = () => {

    const { darkMode, optionsMode } = usePreferences()

    const configureColor = useMemo(() => {
        if(darkMode === optionsMode.DARK) {
            document.body.style = `background: ${COLORS.dark.background}`

            return COLORS.dark
        }
        if(darkMode === optionsMode.LIGTH) {
            document.body.style = `background: ${COLORS.ligth.background}`

            return COLORS.ligth
        }
    }, [darkMode])

    return configureColor

}