import { useDarkMode } from "src/hooks/useDarkMode"
import { useSystemLogos } from "src/hooks/useSystemLogos"
import styles from "../../PrivacyView.module.css"
import { Typography } from "antd";
import { useNavigate } from "react-router";

const { Title } = Typography;

export const TruckportPolicies = () => {

    const [logos, isLoading] = useSystemLogos()
    const { darkMode } = useDarkMode()
    const navigate = useNavigate()

    return (
        <div>
            <header className={styles.header} >
                <img src={darkMode ? logos?.sidebarImageDark?.url : logos?.loginImage?.url} className={styles.logo}
                    onClick={() => navigate("/")}
                />
            </header>

            <main className={styles.contentPrivacy} >
                <Title level={3} className={styles.title} >Privacy policies</Title>

                <Title level={4} className={styles.subtitle} >Introduction</Title>
                <Title level={5} className={styles.info} >
                    Welcome to Truckport App! We highly value your privacy and trust.
                    This comprehensive Privacy Policy aims to provide a detailed understanding of how we handle your personal data when you use our application.
                </Title>

                <Title level={4} className={styles.subtitle} >Data We Collect</Title>
                <Title level={5} className={styles.info} >
                    At Truckport App, we collect only the essential personal data required to provide you with a secure and efficient service:
                </Title>
                <ul>
                    <li>
                        <Title level={5} className={styles.info} >
                            Names: We collect your name to customize your experience and facilitate communication.
                        </Title>
                    </li>
                    <li>
                        <Title level={5} className={styles.info} >
                            Email Addresses: Your email is used for sending order confirmations and maintaining an open line of communication.
                        </Title>
                    </li>
                    <li>
                        <Title level={5} className={styles.info} >
                            Phone Numbers: Phone numbers are essential for efficient communication and delivering updates on your orders.
                        </Title>
                    </li>
                    <li>
                        <Title level={5} className={styles.info} >
                            Real-time Location: To provide real-time order tracking and enhance the accuracy of deliveries, we request access to your location while using the application.
                        </Title>
                    </li>
                </ul>

                <Title level={4} className={styles.subtitle} >How We Collect Data</Title>
                <Title level={5} className={styles.info} >
                    Your privacy is our utmost priority at Truckport App. We collect personal data through two main methods:
                </Title>

                <ul>
                    <li>
                        <Title level={5} className={styles.info} >
                            In-App Contact Forms: We request personal information when you create an account or place orders through our application.
                        </Title>
                    </li>
                    <li>
                        <Title level={5} className={styles.info} >
                            Real-time Location Tracking: To offer real-time order tracking, we seek your consent to access your location.
                        </Title>
                    </li>
                </ul>

                <Title level={4} className={styles.subtitle} >Legal Basis for Data Processing</Title>
                <Title level={5} className={styles.info} >
                    At Truckport App, we treat your consent as the cornerstone of our data processing.
                    We collect and process your personal data exclusively with your consent, which you can withdraw at any time. Your consent is vital for the complete utilization of our application.
                </Title>

                <Title level={4} className={styles.subtitle} >Data Storage</Title>
                <Title level={5} className={styles.info} >
                    Your personal data is securely stored in a cloud-based database. We retain this data for as long as your Truckport App account remains active and functional.
                </Title>

                <Title level={4} className={styles.subtitle} >Data Sharing</Title>
                <Title level={5} className={styles.info} >
                    At Truckport App, we consider your privacy inviolable. We do not share, sell, or lease your personal data with third parties without your explicit consent.
                </Title>

                <Title level={4} className={styles.subtitle} >Data Security</Title>
                <Title level={5} className={styles.info} >
                    Safeguarding your data is our primary responsibility.
                    We employ state-of-the-art security technologies and adhere to rigorous certifications in the backend and databases to ensure that your data is well protected.
                </Title>

                <Title level={4} className={styles.subtitle} >Rights to Access, Modify, and Delete Data</Title>
                <Title level={5} className={styles.info} >
                    You are in control of your data. Truckport App users have the power to access, modify, or delete their personal data directly through in-app forms.
                    If you prefer to request changes or deletion through email or phone call, our support team is ready to provide assistance.
                </Title>

                <Title level={4} className={styles.subtitle} >Use of Cookies</Title>
                <Title level={5} className={styles.info} >
                    We do not employ cookies or similar tracking technologies in the Truckport App application. Your privacy is our top priority, and we respect your online anonymity.
                </Title>

                <Title level={4} className={styles.subtitle} >Data of Minors</Title>
                <Title level={5} className={styles.info} >
                    Truckport App is exclusively intended for use by adults. We do not intentionally collect or process data from minors.
                </Title>

                <Title level={4} className={styles.subtitle} >Data Retention and Deletion Policy</Title>
                <Title level={5} className={styles.info} >
                    Your personal data will remain in our database for as long as your account is active.
                    Should you decide to delete your account, your personal data will be promptly removed from our database to safeguard your privacy.
                </Title>

                <Title level={4} className={styles.subtitle} >Changes to Privacy Policy</Title>
                <Title level={5} className={styles.info} >
                    Keeping you well-informed is of paramount importance to us.
                    If we make substantial changes to our privacy policy, we will provide notice and an opportunity for you to review these modifications.
                </Title>

                <Title level={4} className={styles.subtitle} >Contact Us</Title>
                <Title level={5} className={styles.info} >
                    If you have any questions or concerns regarding our Privacy Policy or the handling of your personal data, please don't hesitate to reach out to our dedicated support team.
                    You can contact us via Noe@truckportexpress.com or 310-233-7559.
                    We are here to protect your privacy and provide you with the best service possible.
                </Title>

                <div className={styles.contentFooter} >
                    <Title level={4} className={styles.footer} >
                        Thank you for entrusting Truckport App. Your privacy remains our top priority, and we are wholeheartedly committed to safeguarding your personal data.
                    </Title>
                </div>
            </main>
        </div>
    )
}