import { Typography } from "antd";
import { ENVS_NAMES, PORTAL_NAME } from "src/Contanst/Contants";
import { TruckportPolicies } from "./Views/TruckportPolicies/TruckportPolicies";
import { PortfleetPolicies } from "./Views/PortfleetPolicies/PortfleetPolicies";
import { DrayxPolicies } from "./Views/DrayxPolicies/DrayxPolicies";

const { Title } = Typography;

export const PrivacyView = () => {

    return (
        <div>
            {PORTAL_NAME === ENVS_NAMES.truckport &&
                <TruckportPolicies/>
            }
            {PORTAL_NAME === ENVS_NAMES.portfleet &&
                <PortfleetPolicies/>
            }
            {PORTAL_NAME === ENVS_NAMES.drayx &&
                <DrayxPolicies/>
            }
        </div>
    )
}